import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { PromptComponent } from '../prompt/prompt.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private promptEvent: any;
  private promptShown = false;

  constructor(private bottomSheet: MatBottomSheet, private platform: Platform) {
    const storedPromptShown = localStorage.getItem('pwaPromptShown');
    this.promptShown = storedPromptShown === 'true';
  }

  initPwaPrompt(): void {
    if (this.platform.ANDROID && !this.promptShown) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS && !this.promptShown) {
      // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
      const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  openPromptComponent(mobileType: 'ios' | 'android'): void {
    if (!this.promptShown) {
      timer(2000)
        .pipe(take(1))
        .subscribe(() => {
          this.bottomSheet.open(PromptComponent, { data: { mobileType, promptEvent: this.promptEvent } });
          this.promptShown = true;
          localStorage.setItem('pwaPromptShown', 'true');
        });
    }
  }

  triggerIosPromptManually(mobileType: 'ios'): void {
    timer(2000)
      .pipe(take(1))
      .subscribe(() => {
        this.bottomSheet.open(PromptComponent, { data: { mobileType } });
      });
  }
}
